import { render, staticRenderFns } from "./OrderDetailRec.vue?vue&type=template&id=517bd56e&scoped=true&"
import script from "./OrderDetailRec.vue?vue&type=script&lang=js&"
export * from "./OrderDetailRec.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517bd56e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnList: require('/home/php1/m-nuxt/components/YfnList.vue').default})
