import { render, staticRenderFns } from "./OrderDetailSet.vue?vue&type=template&id=6b503a0c&scoped=true&"
import script from "./OrderDetailSet.vue?vue&type=script&lang=js&"
export * from "./OrderDetailSet.vue?vue&type=script&lang=js&"
import style0 from "./OrderDetailSet.vue?vue&type=style&index=0&id=6b503a0c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b503a0c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnButton: require('/home/php1/m-nuxt/components/YfnButton.vue').default})
