
import OrderDetailVip from './OrderDetailVip'

export default {
    name: 'OrderDetailProduct',
    components: {
        OrderDetailVip
    },
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object,
    },
    data() {
        return {
            fee: {
                list: []
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            let keyArr = [{
                title: this.$translate('Retail Price'),
                useKey: 'displayRetailPrice',
                key: 'retailPrice',
                class: 'OrderDetailProduct-fee--grey'
            }, {
                title: this.$translate('Subtotal'),
                useKey: this.info.giftCardReceiveEmail ? 'displayRetailPrice' : 'displaySubTotal',
                key: 'subTotal'
            }, {
                title: this.$translate('Shipping Fee'),
                useKey: 'displayShippingFee',
                key: 'shippingFee',
                text: !this.info.shippingFee ? this.$translate('Free') : this.$price(this.info.displayShippingFee,this.info.currencySymbol),
                class: !this.info.shippingFee ? 'color-green' : '',
            }, {
                title: this.$translate('Coupon Discount'),
                subTitle: this.$translate(`(${this.info.couponCode})`),
                useKey: 'displayCouponDiscount',
                key: 'couponDiscount',
                point: '-',
                class: 'OrderDetailProduct-fee--red'
            }, {
                title: this.$translate('Wallet'),
                useKey: 'displayWalletAmount',
                key: 'walletAmount',
                point: '-',
                class: 'OrderDetailProduct-fee--red'
            }, {
                title: this.$translate('Gift Card Balance'),
                useKey: 'displayGiftCardAmount',
                key: 'giftCardAmount',
                point: '-',
                class: 'OrderDetailProduct-fee--red'
            }, {
                title: this.$translate('COD Fee'),
                useKey: 'displayCodServiceFee',
                key: 'codServiceFee'
            }, {
                title: this.$translate('Online Payment Discount'),
                useKey: 'displayOnlinePayDiscount',
                key: 'onlinePayDiscount'
            }, {
                title: this.$translate('Total Price'),
                useKey: 'displayAmtCent',
                key: 'displayAmtCent',
                class: 'OrderDetailProduct-fee--red'
            }];
            this.fee.list = []
            keyArr.forEach(item => {
                if(this.info[item.key]||item.key=='shippingFee') {
                    this.fee.list.push({
                        title: item.title,
                        subTitle: item.subTitle || '',
                        // text: item.text || `${item.point || ''}${this.info.currencySymbol}${this.info[item.useKey]}`,
                        text: item.text || `${item.point || ''}${this.$price(this.info[item.useKey], this.info.currencySymbol)}`,
                        class: item.class || '',
                        key: item.key,
                        visible: item.visible ?? true
                    })
                }
            })
            // 礼品卡
            if(this.info.giftCardReceiveEmail) {
                this.fee.list.forEach(p => {
                    p.visible = ['subTotal', 'displayAmtCent'].includes(p.key)
                })
            }
        },
        // 定制商品-服务图预览
        handlePreCustomize(obj) {
            this.$ImagePreview({
                images: [obj.inputImage || obj.optionValue]
            })
        },
        // 跳转
        jumpProduct(obj) {
            if(obj.goodsType == 12) {
                this.$router.push({
                    name: 'GiftCard',
                })
            } else {
                this.$router.push({
                    name: 'Product-Seo',
                    params: {
                        code: obj.spuCode,
                        seoUrl: obj.seoUrl
                    }
                })
            }
        }
    }
}
