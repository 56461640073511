
export default {
    name: 'OrderDetailMethods',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        fnApi: Object,
        info: Object,
    },
    data() {
        return {
            showPopover: false
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleAddress() {
            this.$Dialog.confirm({
                message: `${this.$translate(`Dear customer, If you want to edit shipping address, please contact us`)}:\nservice@yfn.com`,
                cancelButtonText: this.$translate('Cancel'),
                confirmButtonText: this.$translate('Copy Email'),
                cancelButtonColor: '#999',
                confirmButtonColor: '#333',
            }).then(() => {
                this.fnApi.copy.initial('service@yfn.com')
                this.$Toast(this.$translate('Copied'))
            }).catch(() => {});
        }
    },
}
