
export default {
    name: 'OrderDetailPackage',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        middleEmit: Object,
        info: Object,
    },
    data() {
        return {
            package: {
                visible: false,
                list: [], // 包裹list
                orderItem: [], // 当前包裹下订单商品
                target: {}, // 当前包裹信息
                type: 1, // 当前包裹索引
                length: 0,
                deliveryTimeInfo: {}, // 当前包裹物流信息
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                n.id && this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 包裹数据设置
        setData() {
            let target = {};
            this.package.list = this.info.packageList || []
            this.package.length = this.package.list.length
            if(!this.package.length) {
                return
            }
            target = this.package.list[this.package.type - 1]
            target.orderItemIdList = target.orderItemId.split(',')
            target.orderItemLength = 0
            target.targetTrackInfo = target.trackInfo[0] || {}
            target.deliveryTimeInfo = target.deliveryTimeInfo
            target.status = this.info.orderStatus
            // 物流时间
            let trackTime = target.targetTrackInfo.checkpointDate;
            let hour = (trackTime && trackTime.split(' ')[1].split(':')) || ['', ''];
            target.targetTrackInfo.rsTime = `${hour[0]}:${hour[1]},${this.$translate(this.$dayjs(trackTime).format('MMM'))} ${this.$dayjs(trackTime).format('DD')}`

            this.package.target = target
            this.package.orderItem = this.info.yfnOrderItem.filter(item => {
                let hasId = target.orderItemId.includes(item.id);
                hasId && (target.orderItemLength += item.quantityOrdered)
                return hasId
            }) || []
            this.setStatus()
            this.setTime()
            this.package.visible = this.package.length > 1 || !!this.package.deliveryTimeInfo.timeStr
            this.info.stockStandby && (this.package.visible = false)
            this.middleEmit.fn('setPackage', this.package)
        },
        setStatus() {
            // type-> 订单状态 1 待支付 2 待发货 3 待收货 4 完成 5 已取消 6 退款关闭  7 待评价 8 待审核 9 礼品卡待发送 10 礼品卡已发送 11 礼品卡已兑换
            let target = this.package.target;
            let statusTextArr = ['Unpaid', 'Processing', 'Shipped', 'Delivered', 'Cancelled', 'Refunded', 'Reviews', 'Pending', 'Not Sent', 'Sent', 'Used'];
            let status = ['pending', 'unshipped', 'shipped', 'done', 'canceled', 'close', '', 'pendingaudit', 'waiting send', 'send', 'redeemed'];
            let type = status.indexOf(this.package.target.status) || 0;
            target.statusType = type + 1
            target.statusText = statusTextArr[type]
        },
        setTime() {
            let target = this.package.target;
            let time = target.deliveryTimeInfo || {};
            this.package.deliveryTimeInfo.timeStr = ''
            // 预计送达时间
            if(['Unshipped', 'Shipped', 'Pending'].includes(this.info.orderStatus)) {
                this.package.deliveryTimeInfo.startStr = `${this.$translate(this.$dayjs(time.deliveryStartTime).format('MMM'))} ${this.$dayjs(time.deliveryStartTime).format('DD')} ${this.$dayjs(time.deliveryStartTime).format('YYYY')}`
                this.package.deliveryTimeInfo.endStr = `${this.$translate(this.$dayjs(time.deliveryEndTime).format('MMM'))} ${this.$dayjs(time.deliveryEndTime).format('DD')} ${this.$dayjs(time.deliveryEndTime).format('YYYY')}`
                this.package.deliveryTimeInfo.timeStr = `${this.$translate('About')} ${this.package.deliveryTimeInfo.startStr} - ${this.package.deliveryTimeInfo.endStr}`
            }
        },
        // tab标签
        handleTag(obj) {
            let i = obj.i + 1;
            if(this.package.type == i) {
                return
            }
            this.package.type = i
            this.setData()
        },
        // 定制商品-服务图预览
        handlePreCustomize(obj) {
            this.$ImagePreview({
                images: [obj.inputImage || obj.optionValue]
            })
        },
        // 跳转物流搜索页
        jumpTrack() {
            this.$router.push({
                name: 'TrackSearch',
                query: {
                    order_id: this.info.originOrderId,
                    package_id: this.package.target.id,
                    from: 'orderdetail'
                }
            })
        }
    },
}
