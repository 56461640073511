
export default {
    name: 'OrderDetailVip',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        vip: Object,
    },
    data() {
        return {
            popup: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleTip() {
            this.popup.visible = true
        },
    },
}
