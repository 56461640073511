
export default {
    name: 'OrderDetailStatus',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        fnApi: Object,
        info: Object,
        package: Object,
    },
    data() {
        return {
            status: {
                type: '',
                text: ''
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 复制
        handleCopy() {
            this.fnApi.copy.initial(this.info.originOrderId)
            this.$Toast(this.$translate('Copied'))
        }
    },
}
