
import fnApi from '/utils/common/methods'
import OrderDetailHead from './components/OrderDetailHead'
import OrderDetailStatus from './components/OrderDetailStatus'
import OrderDetailPackage from './components/OrderDetailPackage'
import OrderDetailMethods from './components/OrderDetailMethods'
import OrderDetailProduct from './components/OrderDetailProduct'
import OrderDetailRec from './components/OrderDetailRec'
import OrderDetailSet from './components/OrderDetailSet'

export default {
    name: 'OrderDetail',
    components: {
        OrderDetailHead,
        OrderDetailStatus,
        OrderDetailPackage,
        OrderDetailMethods,
        OrderDetailProduct,
        OrderDetailRec,
        OrderDetailSet
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {},
            fnApi: fnApi,
            middleEmit: {},
            currency: {},
            skeleton: true,
            hasLogin: false,
            base: '',
            info: {},
            packageInfo: { // 包裹信息
                target: {}
            },
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {
        this.getOrderDetailAjax()
    },
    methods: {
        // 获取订单详情接口
        getOrderDetailAjax() {
            this.$api.order.getOrderInfo({
                id: this.query.id
            }).then(response => {
                const res = response.result || {};
                this.info = res
                this.info.yfnAddress
                this.skeleton = false
                const yfnAddress = this.info.yfnAddress
                const yfnBillAddress = this.info.yfnBillAddress
                this.info.rs = {
                    yfnAddress: yfnAddress ? JSON.parse(yfnAddress) : '',
                    yfnBillAddress: yfnBillAddress ? JSON.parse(yfnBillAddress) : '',
                }
                this.setData()
                this.setProductData()
            })
        },
        // 设置数据
        setData() {
            let status = this.setStatus(this.info.orderStatus);
            this.info.rs.statusType = status.type
            this.info.rs.statusText = status.text
            this.info.rs.hasEditAddress = false
            this.info.rs.totalQuantity = 0
            if(['Unshipped'].includes(this.info.orderStatus)) {
                this.info.rs.hasEditAddress = true
            }
            if(this.info.paymentMethod == 'COD' && ['Unpaid'].includes(this.info.orderStatus)) {
                this.info.rs.hasEditAddress = true
            }
            this.currency = {
                symbol: this.info.currencySymbol
            }
        },
        // 设置商品数据
        setProductData() {
            this.info.yfnOrderItem.forEach(item => {
                let status = this.setStatus(item.status);
                item.statusText = status.text
                item.statusType = status.type
                // vip服务
                if(item.vipQuantity) {
                    item.vipService = {...item.vipService, vipQuantity: item.vipQuantity}
                }
                // 金额
                item.salePrice = item.useCurrencyItemPrice.amountCent
                item.orgPrice = item.useCurrencyItemPrice.orgPrice
                item.displaySalePrice = item.useCurrencyItemPrice.displayAmountCent
                item.displayOrgPrice = item.useCurrencyItemPrice.displayOrgPrice
                if(item.goodsType == 3) {
                    item.salePrice = 0
                    item.displaySalePrice = '0.00'
                    item.orgPrice = item.useCurrencyItemPrice.amountCent
                    item.displayOrgPrice = item.useCurrencyItemPrice.displayAmountCent
                }
                this.info.rs.totalQuantity += item.quantityOrdered
            })
        },
        // 设置状态
        setStatus(val) {
            // type-> 订单状态 1 待支付 2 待发货 3 待收货 4 完成 5 已取消 6 退款关闭  7 待评价 8 待审核 9 礼品卡待发送 10 礼品卡已发送 11 礼品卡已兑换
            let statusTextArr = ['Unpaid', 'Processing', 'Shipped', 'Delivered', 'Cancelled', 'Refunded', 'Reviews', 'Pending', 'Not Sent', 'Sent', 'Used'];
            let status = ['pending', 'unshipped', 'shipped', 'done', 'canceled', 'close', '', 'pendingaudit', 'waiting send', 'send', 'redeemed'];
            let type = status.indexOf(val.toLowerCase()) || 0;
            return {
                type: type + 1,
                text: this.$translate(statusTextArr[type])
            }
        },
        // 设置包裹数据
        setPackage(obj) {
            this.packageInfo = obj
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('My Order'),
                keywords: this.$translate('My Order'),
                description: this.$translate('My Order'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.base = this.$router.history.base || ''
            this.middleEmit.fn = (fn, options) => {
                this[fn] && this[fn](options)
            }
            if(process.client) {
                this.hasLogin = this.$login()
            }
        }
    },
}
