
export default {
    name: 'OrderDetailHead',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        currency: Object,
        info: Object,
    },
    data() {
        return {
            time: {
                year: '',
                month: '',
                day: '',
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler(n, o) {
                this.setTime()
            },
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 时间
        setTime() {
            if(!this.info.id) {
                return
            }
            let unix = this.info.createDate;
            this.time.year = this.$dayjs(unix).format('YYYY')
            this.time.month = this.$dayjs(unix).format('MMMM')
            this.time.day = this.$dayjs(unix).format('DD')
            this.listenerEChat()
        },
        // 跳转
        handleCustomer() {
            this.$router.push({
                name: 'ContactUs'
            })
        },
        // 客服
        handleEChat() {
            if(ECHAT) {
                ECHAT.customMiniChat()
            }
        },
        listenerEChat() {
            this.$nextTick(() => {
                let clear = setInterval(() => {
                    try {
                        if(_echat) {
                            clearInterval(clear)
                        }
                        let echatClear = ''
                        _echat('on',{
                            newEvent: (e) => {
                                let status = false
                                if(['showMiniWin', 'newSysMsg'].includes(e.eventAction)) {
                                    status = true
                                } else {
                                    if(['leaveToService'].includes(e.eventLabel)) {
                                        status = true
                                    }
                                }
                                echatClear && clearTimeout(echatClear)
                                echatClear = setTimeout(() => {
                                    status && this.setEChat()
                                }, 800)
                            }
                        });
                    } catch {}
                }, 1000)
            })
        },
        setEChat() {
            if(!ECHAT) return
            let visEvt = {
                eventId: this.info.productCode,
                title: this.info.productName,
                content: `<div style="font-size: 13px;color: #333;line-height: 7px;">
                            <div style="display:flex;align-item: center;color: #f00;">${this.$price(this.info.displaySalePrice, this.currency.symbol)}</span>/div>
                        </div>`,
                imageUrl: this.info.pics[0].url,
                urlForVisitor: `http(${location.href}, 'inner')`,
                urlForStaff: `${location.href}`,
            }
            ECHAT.pushVisitorEvent(visEvt)
        },
    },
}
